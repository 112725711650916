import * as React from "react";
import styled from "styled-components";
import Title from "../components/Title";
import SEO from "../components/SEO";

// markup
function BookOnline() {
  return (
    <BookContent>
      <SEO
        title="CLIPS Hair Studio | Book your next appointment online!"
        description="Book your appointment online today, and let's make you look fantastic!"
        url="https://www.clips-hairstudio.com/book-online/"
      />

      <Title>Book Online</Title>
      <iframe
        src="https://clipshairstudio.as.me/"
        title="Schedule Appointment"
        width="100%"
        height="800"
        frameBorder="0"
      ></iframe>
    </BookContent>
  );
}

export default BookOnline;

const BookContent = styled.div`
  position: relative;
  top: var(--nav-height);
`;
